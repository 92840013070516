import Splide from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

window.addEventListener('load', () => {

    function createSplide(selector, options, extensions={}) {
        if (!document.querySelector(selector)){
            return;
        }

        const carousel = new Splide(selector, options);

        carousel.mount( extensions );
    }

    createSplide('.splide', {
        perPage: 1,
        arrows: false,
        autoplay: true,
        pagination: true,
    }, )
})

