window.addEventListener('load', () => {
    const header = document.querySelector('.header-content');
    const phoneMenu = document.querySelector('.phone-menu');
    const hasChildrenPhone = phoneMenu.querySelectorAll('.menu-item-has-children');
    const hasChildren = header.querySelectorAll('.menu-item-has-children');
    const chevron = '<i class="fa-regular fa-chevron-down"></i>';

    hasChildren.forEach(li => {
        const parent = li.parentElement;
        if (parent.classList.contains('menu')) {
            const link = li.querySelector('a');
            link.innerHTML = link.innerHTML + chevron;
        }
    });

    hasChildrenPhone.forEach(li => {
        const link = li.querySelector('a');
        link.innerHTML = link.innerHTML + chevron;
    });
});

function setSpaceVar() {
    const header = document.querySelector('.HeaderComponent .header-content');
    const space = 0.5 * (window.innerWidth - header.getBoundingClientRect().width);
    document.documentElement.style.setProperty('--container-margin', space + 'px');
}

setSpaceVar();

window.addEventListener('resize', setSpaceVar);
