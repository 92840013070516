window.addEventListener('load', () => {
    textHeight();
})

window.addEventListener('resize', () => {
    textHeight();
})

function textHeight() {
    const textWrappers = document.querySelectorAll<HTMLElement>('.text_image');
    if (window.innerWidth > 991) {
        textWrappers?.forEach(textWrapper => {
            if (textWrapper.classList.contains('default')) {
                const text = textWrapper.querySelector<HTMLElement>('.text_image-content');
                const img = textWrapper.querySelector<HTMLElement>('.img-wrapper');

                if (text.offsetHeight > img.offsetHeight) {
                    textWrapper.style.height = text.offsetHeight + 'px';
                } else {
                    textWrapper.style.height = img.offsetHeight + 'px';
                }
            }
        })
    } else {
        textWrappers?.forEach(textWrapper => {
            textWrapper.style.height = '';
        })


    }
}